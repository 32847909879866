import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { trpc } from '@/lib/trpc/trpc';
interface UserPickerProps {
  selectedUser?: string;
  selectedUserChanged: (user: string) => void;
  withBlankUser?: boolean;
  blankUserText?: string;
  placeholderText?: string;
}
export default function UserPicker({
  ...props
}: UserPickerProps) {
  const allUsers = trpc.User.GetAllUsers.useQuery();
  return <>
      {allUsers.data ? <Select value={props.selectedUser} onValueChange={props.selectedUserChanged}>
          <SelectTrigger>
            <SelectValue placeholder={props.placeholderText || 'Select a user'} />
          </SelectTrigger>
          <SelectContent>
            {allUsers.data.map(user => {
          return <SelectItem key={user.id} value={user.id}>
                  {user.name}
                </SelectItem>;
        })}
            {props.withBlankUser ? <SelectItem value={'none'}>
                {props.blankUserText || '--Not Applicable--'}
              </SelectItem> : <></>}
          </SelectContent>
        </Select> : <Select disabled>
          <SelectTrigger>
            <SelectValue placeholder='loading users...' />
          </SelectTrigger>
        </Select>}
    </>;
}